import BaseSelect from '@/components/common/base-select/base-select.vue'
import Amountinput from '@/components/input/amount-input/amount-input'
import { formatDate } from '@/utils/auth/common'
// import { getDictLists } from '@/filters/fromDict'

// import { validbusinessNo } from '@/utils/validate'
// import supplierSelect from '@/components/packages/supplier-select/supplier-select'
// 查询form
export const dataForm = (content) => {
  return [
    {
      label: '融资业务编号',
      prop: 'businessNo',
      // rules: [{ required: false, validator: validbusinessNo, trigger: 'blur' }],
      attrs: {
        placeholder: '请输入',
        maxlength: 20
      }
    },
    {
      label: '供应商',
      prop: 'gysId',
      attrs: {
        placeholder: '请选择',
        options: content.dropOptions,
        selectedField: ['keyId', 'enterpriseName'],
        filterable: true,
        maxlength: 100
      },
      tag: BaseSelect
      // on: {
      //   change: (data) => {
      //     content.changeQueryGys(data)
      //   }
      // }
    },
    {
      label: '融资状态',
      prop: 'statusCode',
      attrs: {
        placeholder: '请选择',
        options: content.progressList,
        selectedField: ['dictId', 'dictName'],
        filterable: true
      },
      tag: BaseSelect,
      on: {
        change: (data) => {
          content.changeQueryStatus(data)
        }
      }
    },
    {
      label: '业务经理',
      prop: 'busiManagerId',
      attrs: {
        placeholder: '请选择',
        options: content.Manager.busiManagerOptions,
        selectedField: ['busiManagerId', 'busiManagerName'],
        filterable: true
      },
      tag: BaseSelect,
      on: {
        change: (data) => {
          content.changeQuerybusi(data)
        }
      },
      isHidden: content.fold
    },
    // {
    //   label: '风控经理',
    //   prop: 'riskManagerId',
    //   attrs: {
    //     placeholder: '请选择',
    //     options: content.Manager.riskManagerOptions,
    //     selectedField: ['riskManagerId', 'riskManagerName'],
    //     filterable: true
    //   },
    //   tag: BaseSelect,
    //   on: {
    //     change: (data) => {
    //       content.changeQueryrisk(data)
    //     }
    //   },
    //   isHidden: content.fold
    // },
    {
      label: '融资产品',
      prop: 'finaaceProductId',
      attrs: {
        placeholder: '请选择',
        options: content.productOptions,
        selectedField: ['keyId', 'productName'],
        filterable: true
      },
      tag: BaseSelect,
      on: {
        change: (data) => {
          content.changeQueryProduct(data)
        }
      },
      isHidden: content.fold
    },
    {
      label: '资金方',
      prop: 'capitalSideId',
      attrs: {
        placeholder: '请选择',
        options: content.providertData,
        selectedField: ['keyId', 'capitalAndProductName'],
        filterable: true
      },
      tag: BaseSelect,
      on: {
        change: (data) => {
          content.changeCapital(data)
        }
      },
      isHidden: content.fold
    }
    // {
    //   label: '当前审核节点',
    //   prop: 'currentAuditNode',
    //   attrs: {
    //     placeholder: '请选择',
    //     options: content.currentAuditNodeOptions,
    //     selectedField: ['dictId', 'dictName'],
    //     filterable: true
    //   },
    //   tag: BaseSelect,
    //   isHidden: content.fold
    // },
    // {
    //   label: '审核状态',
    //   prop: 'currentAuditStatus',
    //   attrs: {
    //     placeholder: '请选择',
    //     options: content.currentAuditStatusOptions,
    //     selectedField: ['dictId', 'dictName'],
    //     filterable: true
    //   },
    //   tag: BaseSelect,
    //   isHidden: content.fold
    // }

  ]
}
// 表格form
export const dataTable = (context) => {
  return [
    {
      label: '序号',
      prop: 'code',
      width: '80px'
    },
    {
      label: '融资业务编号',
      prop: 'businessNo',
      minWidth: '150px'
    },
    {
      label: '供应商',
      prop: 'gysCompanyName',
      align: 'left',
      minWidth: '150px',
      'show-overflow-tooltip': true
    },
    {
      label: '融资产品',
      prop: 'finaaceProductName',
      minWidth: '100px'
    },
    {
      label: '融资状态',
      prop: 'statusName',
      minWidth: '110px'
    },
    {
      label: '当前节点',
      prop: 'currentAuditNode',
      minWidth: '150px'
      // formatter: row => {
      //   const [obj] = context.currentAuditNodeOptions.filter(item => item.dictId === row.currentAuditNode)
      //   return obj ? obj.dictId : '-'
      // }
    },
    {
      label: '当前节点状态',
      prop: 'currentAuditStatus',
      minWidth: '150px'
      // formatter: row => {
      //   const [obj] = context.currentAuditStatusOptions.filter(item => item.dictId === row.currentAuditNode)
      //   return obj ? obj.dictId : '-'
      // }
    },
    {
      label: '申请金额(元)',
      prop: 'applyAmount',
      minWidth: '150px',
      align: 'right',
      formatter: row => {
        return (row.applyAmount ? row.applyAmount.toLocaleString('en-US', { minimumFractionDigits: 2 }) : '--')
      }
    },
    {
      label: '业务经理',
      prop: 'busiManagerName',
      minWidth: '150px',
      formatter: row => {
        return row.busiManagerName ? row.busiManagerName : '--'
      }
    },
    // {
    //   label: '风控合规岗',
    //   prop: 'riskManagerName',
    //   minWidth: '150px',
    //   formatter: row => {
    //     return row.riskManagerName ? row.riskManagerName : '--'
    //   }
    // },
    // {
    //   label: '法务经理',
    //   prop: 'legalManagerName',
    //   minWidth: '150px',
    //   formatter: row => {
    //     return row.legalManagerName ? row.legalManagerName : '--'
    //   }
    // },
    {
      label: '业务所属区域',
      prop: 'area',
      minWidth: '150px'
    },
    {
      label: '资金方',
      prop: 'capitalSideName',
      minWidth: '150px',
      formatter: ({ capitalSideName, capitalSideProductName }) => {
        if (capitalSideProductName) {
          return `${capitalSideName}-${capitalSideProductName}`
        } else if (capitalSideName) {
          return capitalSideName
        } else {
          return '--'
        }
      }
    },
    {
      label: '申请时间',
      prop: 'addTime',
      formatter: row => {
        return formatDate(row.addTime, 'YY-MM-DD hh:mm:ss')
      },
      minWidth: '150px'
    },
    {
      label: '操作',
      prop: 'action',
      fixed: 'right',
      width: '150px'
    }
  ]
}
// 新增修改form
export const addfromData = (content) => {
  const validatorApplyAmount = (rule, value, callback) => {
    if (value === '') {
      callback(new Error('请输入申请金额'))
    } else if (value - 10000 < 0) {
      callback(new Error('申请金额需大于等于10000元'))
    } else {
      callback()
    }
  }
  const span = 8
  return [
    {
      label: '申请主体:',
      prop: 'gysId',
      attrs: {
        placeholder: '请选择',
        options: content.dropOptions,
        selectedField: ['keyId', 'enterpriseName'],
        filterable: true
      },
      span,
      rules: [{ required: true, message: '请选择申请主体', trigger: 'change' }],
      tag: BaseSelect,
      on: {
        change: (data) => {
          content.changeGys(data)
        }
      }
    },
    {
      label: '融资状态:',
      prop: 'statusName',
      attrs: {
        placeholder: '请选择',
        options: content.progressLists,
        selectedField: ['dictId', 'dictName'],
        filterable: true
      },
      rules: [{ required: true, message: '请选择融资状态', trigger: 'change' }],
      on: {
        change: (data) => {
          content.changeStatus(data)
        }
      },
      span,
      tag: Amountinput
    },
    {
      label: '申请金额(元):',
      prop: 'applyAmount',
      rules: [{ required: true, validator: validatorApplyAmount, trigger: 'blur' }],
      attrs: {
        type: 'Y',
        placeholder: '请输入'
      },
      tag: Amountinput,
      span
    },
    {
      label: '风控经理:',
      prop: 'riskManagerId',
      rules: [{ required: true, message: '请选择风控经理', trigger: 'change' }],
      attrs: {
        placeholder: '请选择',
        options: content.Manager.riskManagerOptions,
        selectedField: ['riskManagerId', 'riskManagerName'],
        filterable: true
      },
      span,
      tag: BaseSelect,
      on: {
        change: (data) => {
          content.changeriskManager(data)
        }
      }
    },
    {
      label: '法务经理:',
      prop: 'legalManagerId',
      attrs: {
        placeholder: '请选择',
        options: content.Manager.legalManagerOptions,
        selectedField: ['legalManagerId', 'legalManagerName'],
        filterable: true
      },
      span,
      tag: BaseSelect,
      on: {
        change: (data) => {
          content.changelegalManager(data)
        }
      }
    },
    {
      label: '业务所属区域:',
      prop: 'areaId',
      rules: [{ required: true, message: '请选择业务所属区域', trigger: 'change' }],
      attrs: {
        placeholder: '请选择',
        options: content.areaList,
        selectedField: ['dictId', 'dictName']
      },
      span,
      tag: BaseSelect,
      on: {
        change: (data) => {
          content.changeArea(data)
        }
      }
    },
    {
      label: '融资产品:',
      prop: 'finaaceProductId',
      rules: [{ required: true, message: '请选择融资产品', trigger: 'change' }],
      attrs: {
        placeholder: '请选择',
        options: content.productOptions,
        selectedField: ['keyId', 'productName'],
        filterable: true
      },
      span,
      tag: BaseSelect,
      on: {
        change: (data) => {
          content.changeProduct(data)
        }
      }
    },
    {
      label: '资金方:',
      prop: 'capitalSideId',
      rules: [{ required: true, message: '请选择资金方', trigger: 'change' }],
      attrs: {
        placeholder: '请选择',
        options: content.providertData,
        selectedField: ['keyId', 'capitalAndProductName'],
        filterable: true
      },
      span,
      tag: BaseSelect,
      on: {
        change: (data) => {
          content.addChangeCapital(data)
        }
      }
    },
    {
      label: '备注：',
      prop: 'memo',
      span: 24,
      attrs: {
        placeholder: '请输入',
        type: 'textarea',
        maxlength: '200',
        rows: 6
      }
    }

  ]
}
// 详情from
export const formInfoconfig = (content) => {
  const span = 8
  return [
    {
      label: '申请主体',
      prop: 'gysCompanyName',
      span
    },
    {
      label: '融资状态',
      prop: 'statusName',
      span
    },
    {
      label: '申请金额',
      prop: 'applyAmount',
      span
    },
    {
      label: '风控经理',
      prop: 'riskManagerName',
      span

    },
    {
      label: '法务经理',
      prop: 'legalManagerName',
      span

    },
    {
      label: '业务所属区域',
      prop: 'area',
      span

    },
    {
      label: '融资产品',
      prop: 'finaaceProductName',
      span

    },
    {
      label: '资金方',
      prop: 'capitalSideName',
      span
    },

    {
      label: '备注',
      prop: 'memo',
      span: 24,
      showAll: true
    }

  ]
}
