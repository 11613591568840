<template>
  <div class="financingBusinessManage">
    <!-- 查询表单 -->
    <base-from
      :componentList="from"
      :formAttrs="{
        model: queryParas,
        labelWidth: '90px',
      }"
      class="formStyle"
      @handleFilter="handleFilter"
      @clearParams="clearParams"
      ref="fromdata"
    >
    <div slot="btn_after">
      <base-button icon='iconfont iconzhankai' class="foldBtn" :class="{PutawayBtn:fold}" type='default' @click="fold=!fold"></base-button>
    </div>
    </base-from>
    <!-- <div class="addbtn">
      <base-button
        @click="addshow"
        label="新增"
        icon="el-icon-plus"
      ></base-button>
    </div> -->

    <!-- 主表格 -->
    <base-table
      :columns="columns"
      :showPage="true"
      :queryParas.sync="queryParas"
      :tableAttrs="{
        data: tableData,
        stripe: true,
      }"
      :loadCount="loadCount"
      :api="api"
      :getApi="'getPage'"
      ref="tableData"
      :dataSource.sync="tableData"
      :webPage="false"
      class="main-page-table"
    >
      <template slot="code" slot-scope="scope">
        {{ scope.$index + 1 }}
      </template>
      <template slot="action" slot-scope="scope">
        <!-- <IconButton
          @click="editFinancing(scope.row)"
          content="修改"
          v-if="editAndDeleteShow(scope.row)"
          icon="iconfont iconbanli"
        ></IconButton> -->
        <!-- <IconButton
          @click="delBusiness(scope.row.keyId)"
          content="删除"
          v-if="editAndDeleteShow(scope.row)"
          icon="iconfont iconshanchu1"
        ></IconButton> -->
        <IconButton
          @click="showDetail(scope.row)"
          content="查看"
          icon="iconfont iconchakan"
        ></IconButton>
      </template>
    </base-table>
    <!-- 新增修改弹出框 -->
    <add-edit-dialog :addfrom="addfrom" :dialogTitle="dialogTitle" :visible.sync="visible" :addqueryParas='addqueryParas' :submitType="submitType" @handleFilter="handleFilter"></add-edit-dialog>
    <!-- 详情弹出框 -->
    <detail-dialog :visibleDetail.sync="visibleDetail" ref="detailForm" :formInfoconfig='formInfoconfig' :detailInfo="detailInfo"></detail-dialog>

  </div>
</template>
<script>
import IconButton from '@/components/common/button/icon-button/icon-button'
import BaseFrom from '@/components/common/base-form//base-form.vue'
import { dataForm, dataTable, addfromData, formInfoconfig } from './utils/config'
import BaseTable from '@/components/common/table/base-table/base-table.vue'
import BaseButton from '@/components/common/button/base-button/base-button.vue'
import { businessManageApi } from '@/api/businessApi'
import { supplierApi } from '@/api/companyApi'
import { getDictLists } from '@/filters/fromDict'
import { productApi } from '@/api/productApi'
import { getManager } from '@/api/sys/user'
import { fundManagementApi } from '@/api/fundManagementApi'
import AddEditDialog from './components/add-edit-dialog.vue'
import DetailDialog from './components/detail-dialog.vue'
import { parameterApi } from '@/api/parameterApi'
import Storage from '@/utils/storage'

export default {
  name: 'financingBusinessManage',
  components: {
    BaseFrom,
    BaseTable,
    BaseButton,
    IconButton,
    AddEditDialog,
    DetailDialog
  },
  data () {
    return {
      capitalOptions: [],
      fold: false,
      // 选择项数据
      dropOptions: [],
      productOptions: [],
      Manager: {
        busiManagerOptions: [],
        riskManagerOptions: [],
        legalManagerOptions: []
      },
      queryParas: {
        pageSize: 10,
        pageIndex: 1
      },
      submitType: '',
      addqueryParas: {
        areaId: ''
      },
      loadCount: 0,
      tableData: [],
      visible: false,
      dialogTitle: '新增',
      count: 0,
      visibleDetail: false,
      detailInfo: {},
      providertData: []
    }
  },
  computed: {
    formInfoconfig () {
      return formInfoconfig(this)
    },
    columns () {
      return dataTable(this)
    },
    from () {
      return dataForm(this)
    },
    api () {
      return businessManageApi
    },
    addfrom () {
      return addfromData(this)
    },
    userInfo () {
      return this.$store.state.user.userInfo
    },
    areaList () {
      return getDictLists('BUSINESS_AREA')
    },
    progressList () {
      return getDictLists('PROGRESS_STATUS')
    },
    progressLists () {
      return getDictLists('PROGRESS_STATUS_INSERT')
    },
    // 审核状态
    currentAuditStatusOptions () {
      return getDictLists('AUDIT_STATUS')
    },
    // 当前审核节点
    currentAuditNodeOptions () {
      return getDictLists('AUDIT_NODES')
    }
  },
  created () {
    this.getResourceData()
  },
  watch: {
    // fold (val) {
    //   this.$nextTick(() => {
    //    // 改变表格高度
    //     this.$refs.tableData.resizeEvent()
    //   })
    // }
  },
  mounted () {
    this.handleFilter()
  },
  methods: {
    // 获取资金方信息
    providertInfo () {
      const params = {
        replyStatus: 1 // 审核状态
      }
      parameterApi.getFundsprovidertInfo(params)
        .then(res => {
          if (res.data) {
            this.providertData = res.data

            this.providertData.forEach(ele => {
              this.$set(ele, 'capitalAndProductName', ele.capitalSideName + '-' + ele.productName)
            })
          }
        })
    },
    // 资金方
    changeCapital (data) {
      const [obj] = this.providertData.filter(item => item.keyId === data)
      this.queryParas.capitalSideName = obj ? obj.capitalSideName : ''
      this.queryParas.capitalSideProductName = obj ? obj.productName : ''
    },
    addChangeCapital (data) {
      const [obj] = this.providertData.filter(item => item.keyId === data)
      this.addqueryParas.capitalSideName = obj.capitalSideName
      this.addqueryParas.capitalSideProductId = obj.capitalSideProductId
      this.addqueryParas.capitalSideProductName = obj.productName
      this.addqueryParas.financeRate = obj.productRate
    },
    // 获取资源数据
    getResourceData () {
      this.providertInfo()
      // 资金方
      fundManagementApi.getCapitalList().then((res) => {
        this.capitalOptions = res.data
      })
      // 申请主体
      supplierApi.listbylabel({ enterpriseLabel: 1 }).then(res => {
        this.dropOptions = res.data
      })
      // 融资产品
      productApi.getProductList().then(res => {
        this.productOptions = res.data.map(item => {
          return { keyId: item.keyId, productName: item.productName }
        })
      })
      // 业务经理busiManager
      getManager({ roleCode: 'ywjl_code' }).then(res => {
        this.Manager.busiManagerOptions = res.data.map(item => {
          return { busiManagerId: item.userId, busiManagerName: item.nickName }
        })
      })
      // 风控经理riskManager
      getManager({ roleCode: 'fkjl_code' }).then(res => {
        this.Manager.riskManagerOptions = res.data.map(item => {
          return { riskManagerId: item.userId, riskManagerName: item.nickName, riskUserName: item.userName }
        })
      })
      // 法务经理legalManager
      getManager({ roleCode: 'fwjl_code' }).then(res => {
        this.Manager.legalManagerOptions = res.data.map(item => {
          return { legalManagerId: item.userId, legalManagerName: item.nickName, legalUserName: item.userName }
        })
      })
    },
    // 编辑和删除按钮显示判断
    editAndDeleteShow (data) {
      return (data.statusCode === '10' || data.statusCode === '15' || data.statusCode === '20' || data.statusCode === '25')
    },
    // 点击新增
    addshow () {
      this.dialogTitle = '新增'
      this.visible = true
      this.submitType = 'add'
      this.addqueryParas = {}
      this.addqueryParas.busiManagerName = this.userInfo.nickName
      this.addqueryParas.busiManagerId = this.userInfo.userId
      // businessManageApi.generate().then((res) => {
      //   this.$set(this.addqueryParas, 'businessNo', res.data)
      // })
    },
    // 融资状态
    changeStatus (data) {
      const [obj] = this.progressList.filter(item => item.dictId === data)
      this.addqueryParas.statusCode = obj ? obj.dictId : ''
      this.addqueryParas.statusName = obj ? obj.dictName : ''
    },
    // 地区
    changeArea (data) {
      const [obj] = this.areaList.filter(item => item.dictId === data)
      this.addqueryParas.area = obj ? obj.dictName : ''
    },
    // 申请主体
    changeGys (data) {
      const [obj] = this.dropOptions.filter(item => item.keyId === data)
      this.addqueryParas.gysCompanyName = obj ? obj.enterpriseName : ''
    },
    // 融资产品
    changeProduct (data) {
      const [obj] = this.productOptions.filter(item => item.keyId === data)
      this.addqueryParas.finaaceProductName = obj ? obj.productName : ''
    },
    // 法务经理
    changelegalManager (data) {
      const [obj] = this.Manager.legalManagerOptions.filter(item => item.legalManagerId === data)
      this.addqueryParas.legalManagerName = obj ? obj.legalManagerName : ''
      this.addqueryParas.legalUserName = obj ? obj.legalUserName : ''
    },
    // 业务经理
    changebusiManager (data) {
      const [obj] = this.Manager.busiManagerOptions.filter(item => item.busiManagerId === data)
      this.addqueryParas.busiManagerName = obj ? obj.busiManagerName : ''
    },
    // 风控经理
    changeriskManager (data) {
      const [obj] = this.Manager.riskManagerOptions.filter(item => item.riskManagerId === data)
      this.addqueryParas.riskManagerName = obj ? obj.riskManagerName : ''
      this.addqueryParas.riskUserName = obj ? obj.riskUserName : ''
    },
    // 查询增加选项的name
    // changeQueryGys (data) {
    //   if (data) {
    //     const obj = this.dropOptions.filter(item => item.keyId === data)
    //     this.$set(this.queryParas, 'gysCompanyName', obj[0].enterpriseName)
    //   } else {
    //     this.$set(this.queryParas, 'gysCompanyName', '')
    //   }
    // },
    // 选择融资状态
    changeQueryStatus (data) {
      if (data) {
        const obj = this.progressList.filter(item => item.dictId === data)
        console.log(obj)
        this.$set(this.queryParas, 'statusName', obj[0].dictName)
      } else {
        this.$set(this.queryParas, 'statusName', '')
      }
    },
    // 选择融资产品
    changeQueryProduct (data) {
      if (data) {
        const obj = this.productOptions.filter(item => item.keyId === data)
        this.$set(this.queryParas, 'finaaceProductName', obj[0].productName)
      } else {
        this.$set(this.queryParas, 'finaaceProductName', '')
      }
    },
    // 选择业务经理
    changeQuerybusi (data) {
      if (data) {
        const obj = this.Manager.busiManagerOptions.filter(item => item.busiManagerId === data)
        this.$set(this.queryParas, 'busiManagerName', obj[0].busiManagerName)
      } else {
        this.$set(this.queryParas, 'busiManagerName', '')
      }
    },
    // 选择风险经理
    changeQueryrisk (data) {
      if (data) {
        const obj = this.Manager.riskManagerOptions.filter(item => item.riskManagerId === data)
        this.$set(this.queryParas, 'riskManagerName', obj[0].riskManagerName)
      } else {
        this.$set(this.queryParas, 'riskManagerName', '')
      }
    },
    handleFilter () {
      this.queryParas.pageIndex = 1
      this.loadCount++
    },
    clearParams () {
      this.$refs.fromdata.clearValidate()
      this.queryParas = {
        pageSize: 10,
        pageIndex: 1
      }
    },
    // 修改
    editFinancing (data) {
      data = JSON.parse(JSON.stringify(data))
      this.dialogTitle = '修改'
      this.visible = true
      this.submitType = 'updata'
      data.applyAmount = data.applyAmount.toString()
      data.legalManagerId = data.legalManagerId === '0' ? '' : data.legalManagerId
      const [legalobj] = this.Manager.legalManagerOptions.filter(item => item.legalManagerId === data.legalManagerId)
      data.legalUserName = legalobj ? legalobj.legalUserName : ''
      const [riskobj] = this.Manager.riskManagerOptions.filter(item => item.riskManagerId === data.riskManagerId)
      data.riskUserName = riskobj ? riskobj.riskUserName : ''
      this.addqueryParas = { ...data }
    },
    // 查看详情
    showDetail (row) {
      this.$router.push({ path: '/business/newDetails', query: { type: 'v' } })
      Storage.setSession('examine', row)

      // this.visibleDetail = true
      // businessManageApi.detail({ keyId: row.keyId }).then((res) => {
      //   res.data.applyAmount = res.data.applyAmount.toLocaleString('en-US', { minimumFractionDigits: 2 })
      //   this.detailInfo = res.data
      //   this.$set(this.detailInfo, 'capitalSideName', res.data.capitalSideName + '-' + res.data.capitalSideProductName)
      //   this.$refs.detailForm.setCount()
      // })
    },
    // 删除
    delBusiness (keyId) {
      this.$confirm('是否确认删除信息?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        businessManageApi.delete({ keyId: keyId }).then(res => {
          if (res.success) {
            this.success('删除成功')
            this.handleFilter()
          }
        })
      }).catch(() => {})
    }
  }
}
</script>
<style scoped lang="scss">
.financingBusinessManage {
  height: 100%;
  .addbtn {
    padding: 15px;
    display: flex;
    justify-content: flex-end;
    background: #fff;
    margin-top: 10px;
    /deep/.el-icon-plus {
      font-size: 20px;
    }
    button {
      height: 34px;
      padding: 0;
      width: 90px;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
.addProcess {
  padding: 20px;
}
.pad20 {
  padding: 20px;
}
// 处理响应式dialog问题

@media screen and (max-width: 1920px) {
    /deep/ .el-dialog--center {
      height: 64%;
    }
  }

  @media screen and (max-width: 1600px) {
    /deep/ .el-dialog--center {
      height: 58%;
    }
  }
  @media screen and (max-width: 1440px) {
    /deep/ .el-dialog--center {
      height: 54%;
    }
  }

  @media screen and (max-width: 1280px) {
    /deep/ .el-dialog--center {
      height: 42%;
    }
}
</style>
